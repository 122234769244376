@keyframes beat-in {
    from { visibility: hidden; transform: scale(1); }
    1% { visibility: visible; transform: scale(1); }
    50% { visibility: visible; transform: scale(1.5); }
    to { visibility: visible; transform: scale(1); }
}

#go-up {
    visibility: hidden;
    display: block;
    width: 3.25rem;
    height: 3.25rem;
    position: fixed;
    left: var(--go-up-left-offset);
    top: var(--go-up-top-offset);
    clip-path: url('#arrow-up');
    background-image: var(--header-bg-fill);
    background-size: 100vw 100vh, 100vw 100vh, 100vw 100vh, auto;
    background-position: calc(0rem - var(--go-up-left-offset)) calc(0rem - var(--go-up-top-offset));
    transition: filter var(--main-transition-duration) ease-in;
    background-attachment: fixed;
    /* background-blend-mode: exclusion; */
}

.night #go-up {
    -webkit-filter: invert(var(--main-filter-amount));
    filter: invert(var(--main-filter-amount));
}

#go-up svg {
    opacity: 0;
}

#go-up.visible {
    animation: beat-in 0.66s ease-out forwards;
}

@media print {
    #go-up {
        display: none;
    }    
}
