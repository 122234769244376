.employment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1.25rem;
}

.employment h4 {
    line-height: 1.75rem;
}

.employment h5 {
    line-height: 1.5rem;
}

.employment span {
    display: block;
    color: var(--main-text-color);
    transition: color var(--main-transition-duration) ease-in;
}

.employment-description ul,
.employment-description ol {
    padding-inline-start: 2.5rem;
}

.employment > div:last-child {
    flex: 3;
    overflow: hidden;
}

.employment > div:first-child * {
    margin-bottom: .5rem;
}

.employment > div:first-child {
    margin-right: 1rem;
    flex: 1;
}

.employment a {
    position: relative;
    display: inline-block;
    text-decoration: none;
}

.company-location {
    display: block;
    margin-top: 0.25rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
}

.work-period {
    font-size: .75rem;
}

.employment .stack {
    list-style: none;
    padding-inline-start: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.employment .stack li {
    font-family: var(--font-text);
    color: var(--main-text-color);
    padding: .25rem .5rem;
    margin-right: .5rem;
    margin-top: .25rem;
    border-radius: .25rem;
    border: solid .0625rem var(--main-text-color);
    transition: color var(--main-transition-duration) ease-in;
}

@media print {
    .employment {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }

    .employment h4 {
        color: darkgray;
    }
    
    .employment h5 {
        color: darkgray;
    }
}
