#title {
    transition: filter var(--main-transition-duration) ease-in;
}

.night #title {
    -webkit-filter: invert(var(--main-filter-amount));
    filter: invert(var(--main-filter-amount));
}

#title a {
    display: block;
}

#title h1 {
    background: var(--header-bg-fill);
    background-attachment: fixed;
    background-position: var(--header-bg-position);
    font-size: var(--h1-font-size);
    line-height: calc(var(--h1-font-size) + 1rem);
    font-family: var(--font-heading);
    font-weight: bold;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    filter: invert(var(--main-filter-amount));
    -webkit-filter: invert(var(--main-filter-amount));
}

#title h2 {
    font-size: var(--h2-font-size);
    font-family: var(--font-heading);
    font-weight: 300;
    color: var(--main-text-color);
    transition: color var(--main-transition-duration) ease-in;
}

.night #title h2 {
    color: #222;
}

@media print {
    #title::before,
    #title::after {
        content: none;
    }

    #title a {
        text-decoration: none;
    }

    #title h1 {
        color: darkgray;
        font-size: var(--h1-font-size);
        font-family: var(--font-heading);
        font-weight: bold;
    }
}
