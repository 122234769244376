.project a {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.project img {
    width: 8rem;
    height: 8rem;
    background-size: contain;
    margin-bottom: 0.25rem;
}

.project a:hover h4::before {
    height: 0.088rem;
    position: absolute;
    width: 100%;
    bottom: 0.25rem;
}

.project h4 {
    position: relative;
}

.project a span {
    color: var(--main-text-color);
    transition: color var(--main-transition-duration) ease-in;
}

@media print {
    .project h4 {
        -webkit-text-decoration-color: unset;
        text-decoration-color: unset;
        transition: unset;
        -webkit-text-decoration-style: unset;
        text-decoration-style: solid;
        -webkit-text-decoration-thickness: unset;
        text-decoration-thickness: unset;
    }

    .project a:hover h4 {
        text-decoration: underline;
        -webkit-text-decoration-color: unset;
        text-decoration-color: unset;
    }
}
