.reference {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background: unset;
    -webkit-text-fill-color: unset;
    -webkit-background-clip: unset;
    background-clip: unset;
}

.reference h4 {
    font-size: 1.5rem;
    font-weight: 400;
    font-family: var(--font-heading);
}

.reference h5 {
    font-family: var(--font-heading);
    font-size: 1.25rem;
    font-weight: 400;
    color: var(--main-text-color);
    transition: color var(--main-transition-duration) ease-in;
}

.reference span,
.reference a {
    position: relative;
    display: inline-block;
}

.reference span {
    color: var(--main-text-color);
    margin-bottom: .25rem;
    transition: color var(--main-transition-duration) ease-in;
}

@media print {

}
