#header {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    margin-bottom: 0.5rem;
}

#header::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: var(--header-height);
    background: var(--header-bg-fill);
    background-attachment: fixed;
    background-position: var(--header-bg-position);
    box-shadow: 0 0 3rem 1rem #fffffc88;
    transition: filter var(--main-transition-duration) ease-in;
    z-index: -1;
}

.night #header::before {
    -webkit-filter: invert(var(--main-filter-amount));
    filter: invert(var(--main-filter-amount));
}

#details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    grid-column: 1;
    grid-row: 1;
}

#location {
    display: none;
}

#links {
    list-style: none;
    padding-inline-start: 0;
    margin-block-end: 0;
    position: absolute;
    top: calc(var(--header-height) - 0.8 * var(--link-icon-height));
}

@media print {
    #header {
        position: relative;
    }

    #header::before {
        content: unset;
    }

    #location {
        margin-top: .5rem;
        display: block;
        color: lightgray;
        font-size: 1rem;
    }

    #links {
        position: unset;
        top: unset;
        margin-top: 1.25rem;
        margin-bottom: 0.75rem;
    }
}
