#profile ul,
#profile ol {
    padding-inline-start: 2.5rem;
}

#profile a {
    position: relative;
    display: inline-block;
}

#projects,
#references {
    list-style: none;
    padding-inline-start: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 2rem;
}

#references {
    grid-template-columns: 1fr 1fr;
}

#skills,
#languages,
#references {
    column-count: 2;
    column-gap: 4rem;
}

#references {
    list-style: none;
    padding-inline-start: 0;
}

@media print {
    #content {
        display: block;
        position: relative;
    }

    .section.employments {
        -webkit-column-break-inside: auto;
        page-break-inside: auto;
        break-inside: auto;
    }

    .projects {
        display: none;
    }
}
