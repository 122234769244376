#photo {
    grid-column: 2;
    grid-row: 1;
}

#photo div {
    display: block;
    width: 12.5rem;
    height: 12.5rem;
    background-size: 15rem auto;
    background-position: center;
    border-radius: 50%;
    border: .5rem solid white;
    box-shadow: 0 0 0 .03125rem var(--main-text-color);
    transition: border-color var(--main-transition-duration) ease-in,
        box-shadow var(--main-transition-duration) ease-in;
}

.night #photo div {
    border-color: #214d;
    box-shadow: 0 0 0 .03125rem #0008;
}

@media print {
    #photo {
        margin-top: .25mm;
        margin-right: .25mm;
    }

    #photo div {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}
