.link {
    position: relative;
    display: inline-block;
    margin-right: 1.5rem;
}

.link.print-only {
    display: none;
}

.link span {
    display: none;
}

.link a {
    font-size: 0 !important;
}

.link a::before {
    background: var(--header-bg-fill);
    background-attachment: fixed;
    background-position: var(--header-bg-position);
}

.link a::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -4%;
    left: -8%;
    right: -8%;
    height: 32%;
    border-radius: 50%;
    transition: filter var(--main-transition-duration) ease-in,
        box-shadow 0.12s ease-in-out;
}

.night .link a::before {
    -webkit-filter: invert(var(--main-filter-amount));
    filter: invert(var(--main-filter-amount));
}

.link a img {
    position: relative;
    height: var(--link-icon-height);
    width: auto;
    transition: transform 0.12s ease-in;
}

.link a:hover img {
    transform: scale(1.2);
}

.link a:hover::before {
    box-shadow: 0 0 2rem .25rem #ffb;
}

.night .link a:hover::before {
    box-shadow: 0 0 2rem .25rem #0042;
}

@media print {
    .link {
        display: flex;
        align-items: center;
    }

    .link.print-only {
        display: flex;
    }

    .link span {
        display: inline-block;
        color: lightgray;
        font-size: 1rem !important;
        flex: 1;
    }

    .link a {
        flex: 2;
        font-size: 1rem !important;
    }

    .link a::after,
    .link a::before {
        content: unset;
    }

    .link a img {
        display: none;
    }
}
