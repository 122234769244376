#app {
  --main-text-color: #484848;
  --main-transition-duration: 0.25s;
  --main-filter-amount: 96%;
  --page-width: 51rem;
  --page-h-padding: 6rem;
  --page-v-padding: 2.25rem;
  --page-h-margin: calc(100vw / 2 - var(--page-width) / 2);
  --go-up-left-offset: calc(100vw - var(--page-h-margin) + 2rem);
  --go-up-top-offset: calc(100vh - 4rem);
  --link-icon-height: 2.25rem;
  --header-height: 12.5rem;
  --h1-font-size: 3rem;
  --h2-font-size: 1.5rem;
  --header-bg-position: top center;
  --header-bg-fill: linear-gradient(312deg, #005ad4dd, #15d8fb00 75%),
    linear-gradient(172deg, #ffcc00dd, #15d8fb00 50%),
    linear-gradient(48deg, #257500dd, #ffcc0000), url("/src/assets/fish.svg");
  --header-bg-fill-flipped: linear-gradient(172deg, #005ad4dd, #15d8fb00 75%),
    linear-gradient(312deg, #ffcc00dd, #15d8fb00 50%),
    linear-gradient(-48deg, #257500dd, #ffcc0000), url("/src/assets/fish.svg");
}

#app.night {
  --main-text-color: #b8b8b8;
}

#app::before {
  content: "";
  position: fixed;
  top: -10rem;
  left: -10rem;
  width: calc(100% + 20rem);
  height: calc(100vh + 20rem);
  background-image: url("../../assets/blobs2.svg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 160%;
  opacity: 0.88;
  transition: filter var(--main-transition-duration) ease-in;
}

#app.night::before {
  -webkit-filter: invert(var(--main-filter-amount));
  filter: invert(var(--main-filter-amount));
}

#controls {
  position: fixed;
  top: 1.6rem;
  left: var(--go-up-left-offset);
  display: flex;
  gap: 1.2rem;
  align-items: center;
}

#controls ul {
  list-style: none;
  margin-block-end: 0;
  position: relative;
}

#controls .supported-lang {
  position: absolute;
  text-align: right;
  width: 100%;
  top: 100%;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease-in;
}

#controls .selected-lang a {
  display: flex;
  align-items: center;
}

#controls #lang-switch a {
  cursor: pointer;
}

#controls #lang-switch a span {
  margin-left: 0.5rem;
  text-shadow: 0 0 0.6rem #ff0e, 1px 0 0 #f0fc, -1px 0 0 #0ffd;
  /* text-shadow: 0 0 0.5rem #fffe; */
}

.night #controls #lang-switch span,
.night #controls #lang-switch .lang-icon-shadow {
  filter: invert(1);
}

#controls #lang-switch:hover .supported-lang {
  opacity: 1;
  height: unset;
}

#controls #lang-switch a:hover span {
  text-underline-offset: 1px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  -moz-text-decoration-line: underline;
  -moz-text-decoration-thickness: 1px;
}

#controls svg {
  overflow: visible;
  width: auto;
  cursor: pointer;
  transition: filter var(--main-transition-duration) ease-in, transform 0.06125s ease-in;
  -webkit-filter: drop-shadow(0 -0.25rem 1rem #0020);
  filter: drop-shadow(0 -0.25rem 1rem #0020);
}

#theme-toggle svg {
  height: 2.5rem;
}

#lang-switch svg {
  height: 1.75rem;
  padding-top: 0.25rem;
}

#controls a:hover svg,
#controls #lang-switch:hover svg {
  transform: scale(1.12);
}

#controls a:active svg {
  transform: scale(0.96);
}

#printer svg .paper {
  fill: #f9eab0;
}

#printer svg .paper-shadow {
  fill: #d9aa90;
}

#printer svg .paper-writing {
  fill: #888;
}

#printer svg .printer-top {
  fill: #bbb;
  transition: fill var(--main-transition-duration) ease-in;
}

#printer svg .printer-input,
#printer svg .printer-base {
  fill: #888;
  transition: fill var(--main-transition-duration) ease-in;
}

/* .night #printer svg .printer-top {
    fill: #616161;
}

.night #printer svg .printer-input,
.night #printer svg .printer-base {
    fill: #424242;
} */

.night #theme-toggle svg {
  -webkit-filter: drop-shadow(0 -0.25rem 1rem #ffd8);
  filter: drop-shadow(0 -0.25rem 1rem #ffd8);
}

#theme-toggle #glare {
  fill: #fffc;
}

#theme-toggle #filament {
  fill: #0008;
  transition: fill var(--main-transition-duration) ease-in;
}

.night #theme-toggle #filament {
  fill: #fffe;
}

#theme-toggle #glass {
  fill: #888b;
  transition: fill var(--main-transition-duration) ease-in;
}

.night #theme-toggle #glass {
  fill: #f4e028dd;
}

#page {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: var(--page-width);
  box-shadow: 0 0 6rem #fff8;
  margin: 0 auto;
  padding: var(--page-v-padding) var(--page-h-padding);
  box-sizing: border-box;
  transition: box-shadow var(--main-transition-duration) ease-in,
    background-color var(--main-transition-duration) ease-in;
  background-color: #fff8;
  -webkit-backdrop-filter: blur(2.25rem);
  backdrop-filter: blur(2.25rem);
}

.night #page {
  box-shadow: 0 0 6rem #ffe2;
  background-color: #0008;
}

@media print {
  #app {
    position: unset;
    overflow-y: unset;
    overscroll-behavior: unset;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    background-color: unset;
    background-image: unset;
  }

  #app.night {
    background-color: unset;
    opacity: unset;
  }

  #app::before {
    position: unset;
    overflow-y: unset;
    overscroll-behavior: unset;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    background-color: unset;
    background-image: unset;
  }

  #app.night::before {
    filter: unset;
  }

  #page {
    background-color: unset;
    margin: 0;
    padding: 0;
    width: unset;
    min-width: unset;
    box-shadow: unset;
  }

  .night #page {
    box-shadow: unset;
  }

  #page::before {
    content: unset;
  }

  .night #page::before {
    filter: unset;
  }
}
