html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

#title {
  transition: filter var(--main-transition-duration) ease-in;
}

.night #title {
  -webkit-filter: invert(var(--main-filter-amount));
  filter: invert(var(--main-filter-amount));
}

#title a {
  display: block;
}

#title h1 {
  background: var(--header-bg-fill);
  background-attachment: fixed;
  background-position: var(--header-bg-position);
  font-size: var(--h1-font-size);
  line-height: calc(var(--h1-font-size)  + 1rem);
  font-family: var(--font-heading);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  color: #0000;
  filter: invert(var(--main-filter-amount));
  -webkit-filter: invert(var(--main-filter-amount));
  font-weight: bold;
}

#title h2 {
  font-size: var(--h2-font-size);
  font-family: var(--font-heading);
  color: var(--main-text-color);
  transition: color var(--main-transition-duration) ease-in;
  font-weight: 300;
}

.night #title h2 {
  color: #222;
}

@media print {
  #title:before, #title:after {
    content: none;
  }

  #title a {
    text-decoration: none;
  }

  #title h1 {
    color: #a9a9a9;
    font-size: var(--h1-font-size);
    font-family: var(--font-heading);
    font-weight: bold;
  }
}

.skill {
  margin-bottom: .25rem;
  display: flex;
}

.skill h6 {
  font-family: var(--font-text);
  color: var(--main-text-color);
  transition: color var(--main-transition-duration) ease-in;
  flex: 1;
}

.skill span {
  width: 6rem;
  height: 1rem;
  transition: background-color var(--main-transition-duration) ease-in;
  background-color: #0000000d;
  border-radius: .5rem;
  padding: 0;
  font-size: 0;
  display: block;
  overflow: hidden;
}

.skill a {
  height: 100%;
  cursor: default;
  background: var(--header-bg-fill);
  background-attachment: fixed;
  background-position: var(--header-bg-position);
  transition: filter var(--main-transition-duration) ease-in;
  display: inline-block;
}

.night .skill a {
  -webkit-filter: invert(var(--main-filter-amount));
  filter: invert(var(--main-filter-amount));
}

.night .skill span {
  background-color: #ffffff0d;
}

@media print {
  .skill span {
    position: relative;
  }

  .skill a {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-attachment: unset;
    background-position: unset;
    filter: unset;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;
    position: absolute;
    inset: 0;
  }

  #languages .skill span {
    width: unset;
    border: unset;
    border-radius: unset;
    overflow: visible;
    font-size: 1rem !important;
  }

  #languages .skill a {
    display: none;
  }
}

.section {
  margin-bottom: 2rem;
}

.section p, .section li {
  color: var(--main-text-color);
  transition: color var(--main-transition-duration) ease-in;
}

.cut-out, .section h3, .section h4, .main-hypertext a {
  background: var(--header-bg-fill);
  -webkit-text-fill-color: transparent;
  background-attachment: fixed;
  -webkit-background-clip: text;
  transition: filter var(--main-transition-duration) ease-in;
  background-position: -50%;
  -webkit-background-clip: text;
  background-clip: text;
}

.night .cut-out, .night .section h3, .night .section h4, .night .main-hypertext a {
  -webkit-filter: invert(var(--main-filter-amount));
  filter: invert(var(--main-filter-amount));
}

.section h3 {
  font-family: var(--font-heading);
  padding-bottom: .75rem;
  font-size: 2rem;
  font-weight: 500;
}

#page.isFirefox .section h3 {
  background-size: 400%;
  background-attachment: unset;
}

.section h4 {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: var(--font-heading);
}

.main-hypertext a {
  transition: text-decoration-color .25s ease-in, filter var(--main-transition-duration) ease-in;
  position: relative;
}

.project a:hover h4:before, .main-hypertext a:hover:before {
  content: "";
  height: .066rem;
  background: var(--header-bg-fill);
  width: 100%;
  background-position: -50%;
  background-attachment: fixed;
  display: block;
  position: absolute;
  bottom: -.033rem;
}

.section h5 {
  font-family: var(--font-heading);
  color: var(--main-text-color);
  transition: color var(--main-transition-duration) ease-in;
  font-size: 1.25rem;
  font-weight: 400;
}

@media print {
  .section {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 1rem;
  }

  .cut-out, .section h3, .section h4, .main-hypertext a, .night .cut-out, .night .section h3, .night .section h4, .night .main-hypertext a {
    background: unset;
    background-attachment: unset;
    -webkit-text-fill-color: unset;
    -webkit-background-clip: unset;
    background-clip: unset;
    background-position: unset;
    color: #a9a9a9;
    filter: unset;
  }

  .main-hypertext a, .night .main-hypertext a {
    color: unset;
  }

  .section h5 {
    color: gray;
  }
}

.reference {
  background: unset;
  -webkit-text-fill-color: unset;
  -webkit-background-clip: unset;
  background-clip: unset;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.reference h4 {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: var(--font-heading);
}

.reference h5 {
  font-family: var(--font-heading);
  color: var(--main-text-color);
  transition: color var(--main-transition-duration) ease-in;
  font-size: 1.25rem;
  font-weight: 400;
}

.reference span, .reference a {
  display: inline-block;
  position: relative;
}

.reference span {
  color: var(--main-text-color);
  transition: color var(--main-transition-duration) ease-in;
  margin-bottom: .25rem;
}

.project a {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.project img {
  width: 8rem;
  height: 8rem;
  background-size: contain;
  margin-bottom: .25rem;
}

.project a:hover h4:before {
  height: .088rem;
  width: 100%;
  position: absolute;
  bottom: .25rem;
}

.project h4 {
  position: relative;
}

.project a span {
  color: var(--main-text-color);
  transition: color var(--main-transition-duration) ease-in;
}

@media print {
  .project h4 {
    -webkit-text-decoration-color: unset;
    text-decoration-color: unset;
    -webkit-text-decoration-style: unset;
    -webkit-text-decoration-thickness: unset;
    text-decoration-style: solid;
    text-decoration-thickness: unset;
    transition: unset;
  }

  .project a:hover h4 {
    text-decoration: underline;
    -webkit-text-decoration-color: unset;
    text-decoration-color: unset;
  }
}

#photo {
  grid-area: 1 / 2;
}

#photo div {
  width: 12.5rem;
  height: 12.5rem;
  box-shadow: 0 0 0 .03125rem var(--main-text-color);
  transition: border-color var(--main-transition-duration) ease-in, box-shadow var(--main-transition-duration) ease-in;
  background-position: center;
  background-size: 15rem;
  border: .5rem solid #fff;
  border-radius: 50%;
  display: block;
}

.night #photo div {
  border-color: #214d;
  box-shadow: 0 0 0 .03125rem #0008;
}

@media print {
  #photo {
    margin-top: .25mm;
    margin-right: .25mm;
  }

  #photo div {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.link {
  margin-right: 1.5rem;
  display: inline-block;
  position: relative;
}

.link.print-only, .link span {
  display: none;
}

.link a {
  font-size: 0 !important;
}

.link a:before {
  background: var(--header-bg-fill);
  background-attachment: fixed;
  background-position: var(--header-bg-position);
  content: "";
  height: 32%;
  transition: filter var(--main-transition-duration) ease-in, box-shadow .12s ease-in-out;
  border-radius: 50%;
  display: block;
  position: absolute;
  bottom: -4%;
  left: -8%;
  right: -8%;
}

.night .link a:before {
  -webkit-filter: invert(var(--main-filter-amount));
  filter: invert(var(--main-filter-amount));
}

.link a img {
  height: var(--link-icon-height);
  width: auto;
  transition: transform .12s ease-in;
  position: relative;
}

.link a:hover img {
  transform: scale(1.2);
}

.link a:hover:before {
  box-shadow: 0 0 2rem .25rem #ffb;
}

.night .link a:hover:before {
  box-shadow: 0 0 2rem .25rem #0042;
}

@media print {
  .link {
    align-items: center;
    display: flex;
  }

  .link.print-only {
    display: flex;
  }

  .link span {
    color: #d3d3d3;
    flex: 1;
    display: inline-block;
    font-size: 1rem !important;
  }

  .link a {
    flex: 2;
    font-size: 1rem !important;
  }

  .link a:after, .link a:before {
    content: unset;
  }

  .link a img {
    display: none;
  }
}

#header {
  grid-gap: 1rem;
  grid-template-rows: 1fr;
  grid-template-columns: 3fr 1fr;
  margin-bottom: .5rem;
  display: grid;
}

#header:before {
  content: "";
  height: var(--header-height);
  background: var(--header-bg-fill);
  background-attachment: fixed;
  background-position: var(--header-bg-position);
  transition: filter var(--main-transition-duration) ease-in;
  z-index: -1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 3rem 1rem #fffffc88;
}

.night #header:before {
  -webkit-filter: invert(var(--main-filter-amount));
  filter: invert(var(--main-filter-amount));
}

#details {
  flex-direction: column;
  grid-area: 1 / 1;
  display: flex;
}

#location {
  display: none;
}

#links {
  top: calc(var(--header-height)  - .8 * var(--link-icon-height));
  margin-block-end: 0;
  padding-inline-start: 0;
  list-style: none;
  position: absolute;
}

@media print {
  #header {
    position: relative;
  }

  #header:before {
    content: unset;
  }

  #location {
    color: #d3d3d3;
    margin-top: .5rem;
    font-size: 1rem;
    display: block;
  }

  #links {
    position: unset;
    top: unset;
    margin-top: 1.25rem;
    margin-bottom: .75rem;
  }
}

@keyframes beat-in {
  from {
    visibility: hidden;
    transform: scale(1);
  }

  1% {
    visibility: visible;
    transform: scale(1);
  }

  50% {
    visibility: visible;
    transform: scale(1.5);
  }

  to {
    visibility: visible;
    transform: scale(1);
  }
}

#go-up {
  visibility: hidden;
  width: 3.25rem;
  height: 3.25rem;
  left: var(--go-up-left-offset);
  top: var(--go-up-top-offset);
  clip-path: url("#arrow-up");
  background-image: var(--header-bg-fill);
  background-size: 100vw 100vh, 100vw 100vh, 100vw 100vh, auto;
  background-position: calc(0rem - var(--go-up-left-offset)) calc(0rem - var(--go-up-top-offset));
  transition: filter var(--main-transition-duration) ease-in;
  background-attachment: fixed;
  display: block;
  position: fixed;
}

.night #go-up {
  -webkit-filter: invert(var(--main-filter-amount));
  filter: invert(var(--main-filter-amount));
}

#go-up svg {
  opacity: 0;
}

#go-up.visible {
  animation: .66s ease-out forwards beat-in;
}

@media print {
  #go-up {
    display: none;
  }
}

.employment {
  margin-bottom: 1.25rem;
  display: flex;
}

.employment h4 {
  line-height: 1.75rem;
}

.employment h5 {
  line-height: 1.5rem;
}

.employment span {
  color: var(--main-text-color);
  transition: color var(--main-transition-duration) ease-in;
  display: block;
}

.employment-description ul, .employment-description ol {
  padding-inline-start: 2.5rem;
}

.employment > div:last-child {
  flex: 3;
  overflow: hidden;
}

.employment > div:first-child * {
  margin-bottom: .5rem;
}

.employment > div:first-child {
  flex: 1;
  margin-right: 1rem;
}

.employment a {
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.company-location {
  margin-top: .25rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  display: block;
}

.work-period {
  font-size: .75rem;
}

.employment .stack {
  flex-wrap: wrap;
  padding-inline-start: 0;
  list-style: none;
  display: flex;
}

.employment .stack li {
  font-family: var(--font-text);
  color: var(--main-text-color);
  border: solid .0625rem var(--main-text-color);
  transition: color var(--main-transition-duration) ease-in;
  border-radius: .25rem;
  margin-top: .25rem;
  margin-right: .5rem;
  padding: .25rem .5rem;
}

@media print {
  .employment {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .employment h4, .employment h5 {
    color: #a9a9a9;
  }
}

#profile ul, #profile ol {
  padding-inline-start: 2.5rem;
}

#profile a {
  display: inline-block;
  position: relative;
}

#projects, #references {
  grid-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  padding-inline-start: 0;
  list-style: none;
  display: grid;
}

#references {
  grid-template-columns: 1fr 1fr;
}

#skills, #languages, #references {
  column-count: 2;
  column-gap: 4rem;
}

#references {
  padding-inline-start: 0;
  list-style: none;
}

@media print {
  #content {
    display: block;
    position: relative;
  }

  .section.employments {
    -webkit-column-break-inside: auto;
    page-break-inside: auto;
    break-inside: auto;
  }

  .projects {
    display: none;
  }
}

#app {
  --main-text-color: #484848;
  --main-transition-duration: .25s;
  --main-filter-amount: 96%;
  --page-width: 51rem;
  --page-h-padding: 6rem;
  --page-v-padding: 2.25rem;
  --page-h-margin: calc(100vw / 2 - var(--page-width) / 2);
  --go-up-left-offset: calc(100vw - var(--page-h-margin)  + 2rem);
  --go-up-top-offset: calc(100vh - 4rem);
  --link-icon-height: 2.25rem;
  --header-height: 12.5rem;
  --h1-font-size: 3rem;
  --h2-font-size: 1.5rem;
  --header-bg-position: top center;
  --header-bg-fill: linear-gradient(312deg, #005ad4dd, #15d8fb00 75%), linear-gradient(172deg, #fc0d, #15d8fb00 50%), linear-gradient(48deg, #257500dd, #fc00), url("fish.3a64c8a2.svg");
  --header-bg-fill-flipped: linear-gradient(172deg, #005ad4dd, #15d8fb00 75%), linear-gradient(312deg, #fc0d, #15d8fb00 50%), linear-gradient(-48deg, #257500dd, #fc00), url("fish.3a64c8a2.svg");
}

#app.night {
  --main-text-color: #b8b8b8;
}

#app:before {
  content: "";
  width: calc(100% + 20rem);
  height: calc(100vh + 20rem);
  opacity: .88;
  transition: filter var(--main-transition-duration) ease-in;
  background-image: url("blobs2.a19ceb2e.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 160%;
  background-attachment: fixed;
  position: fixed;
  top: -10rem;
  left: -10rem;
}

#app.night:before {
  -webkit-filter: invert(var(--main-filter-amount));
  filter: invert(var(--main-filter-amount));
}

#controls {
  top: 1.6rem;
  left: var(--go-up-left-offset);
  align-items: center;
  gap: 1.2rem;
  display: flex;
  position: fixed;
}

#controls ul {
  margin-block-end: 0;
  list-style: none;
  position: relative;
}

#controls .supported-lang {
  text-align: right;
  width: 100%;
  opacity: 0;
  height: 0;
  transition: opacity .5s ease-in;
  position: absolute;
  top: 100%;
  overflow: hidden;
}

#controls .selected-lang a {
  align-items: center;
  display: flex;
}

#controls #lang-switch a {
  cursor: pointer;
}

#controls #lang-switch a span {
  text-shadow: 0 0 .6rem #ff0e, 1px 0 #f0fc, -1px 0 #0ffd;
  margin-left: .5rem;
}

.night #controls #lang-switch span, .night #controls #lang-switch .lang-icon-shadow {
  filter: invert();
}

#controls #lang-switch:hover .supported-lang {
  opacity: 1;
  height: unset;
}

#controls #lang-switch a:hover span {
  text-underline-offset: 1px;
  -moz-text-decoration-thickness: 1px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

#controls svg {
  width: auto;
  cursor: pointer;
  transition: filter var(--main-transition-duration) ease-in, transform 61.25ms ease-in;
  -webkit-filter: drop-shadow(0 -.25rem 1rem #0020);
  filter: drop-shadow(0 -.25rem 1rem #0020);
  overflow: visible;
}

#theme-toggle svg {
  height: 2.5rem;
}

#lang-switch svg {
  height: 1.75rem;
  padding-top: .25rem;
}

#controls a:hover svg, #controls #lang-switch:hover svg {
  transform: scale(1.12);
}

#controls a:active svg {
  transform: scale(.96);
}

#printer svg .paper {
  fill: #f9eab0;
}

#printer svg .paper-shadow {
  fill: #d9aa90;
}

#printer svg .paper-writing {
  fill: #888;
}

#printer svg .printer-top {
  fill: #bbb;
  transition: fill var(--main-transition-duration) ease-in;
}

#printer svg .printer-input, #printer svg .printer-base {
  fill: #888;
  transition: fill var(--main-transition-duration) ease-in;
}

.night #theme-toggle svg {
  -webkit-filter: drop-shadow(0 -.25rem 1rem #ffd8);
  filter: drop-shadow(0 -.25rem 1rem #ffd8);
}

#theme-toggle #glare {
  fill: #fffc;
}

#theme-toggle #filament {
  fill: #0008;
  transition: fill var(--main-transition-duration) ease-in;
}

.night #theme-toggle #filament {
  fill: #fffe;
}

#theme-toggle #glass {
  fill: #888b;
  transition: fill var(--main-transition-duration) ease-in;
}

.night #theme-toggle #glass {
  fill: #f4e028dd;
}

#page {
  width: var(--page-width);
  padding: var(--page-v-padding) var(--page-h-padding);
  box-sizing: border-box;
  transition: box-shadow var(--main-transition-duration) ease-in, background-color var(--main-transition-duration) ease-in;
  -webkit-backdrop-filter: blur(2.25rem);
  -webkit-backdrop-filter: blur(2.25rem);
  backdrop-filter: blur(2.25rem);
  background-color: #fff8;
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 6rem #fff8;
}

.night #page {
  background-color: #0008;
  box-shadow: 0 0 6rem #ffe2;
}

@media print {
  #app {
    position: unset;
    overflow-y: unset;
    overscroll-behavior: unset;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    background-color: unset;
    background-image: unset;
  }

  #app.night {
    background-color: unset;
    opacity: unset;
  }

  #app:before {
    position: unset;
    overflow-y: unset;
    overscroll-behavior: unset;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    background-color: unset;
    background-image: unset;
  }

  #app.night:before {
    filter: unset;
  }

  #page {
    background-color: unset;
    width: unset;
    min-width: unset;
    box-shadow: unset;
    margin: 0;
    padding: 0;
  }

  .night #page {
    box-shadow: unset;
  }

  #page:before {
    content: unset;
  }

  .night #page:before {
    filter: unset;
  }
}

/*# sourceMappingURL=index.1f1efc59.css.map */
