.section {
    margin-bottom: 2rem;
}

.section p,
.section li {
    color: var(--main-text-color);
    transition: color var(--main-transition-duration) ease-in;
}

.cut-out,
.section h3,
.section h4,
.main-hypertext a {
    /* background: var(--header-bg-fill-flipped); */
    background: var(--header-bg-fill);
    background-attachment: fixed;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: -50%;
    transition: filter var(--main-transition-duration) ease-in;
}

.night .cut-out,
.night .section h3,
.night .section h4,
.night .main-hypertext a {
    -webkit-filter: invert(var(--main-filter-amount));
    filter: invert(var(--main-filter-amount));
}

.section h3 {
    font-family: var(--font-heading);
    padding-bottom: 0.75rem;
    font-size: 2rem;
    font-weight: 500;
}

#page.isFirefox .section h3 {
    background-size: 400%;
    background-attachment: unset; /* https://bugzilla.mozilla.org/show_bug.cgi?id=1416885 */
}

.section h4 {
    font-size: 1.5rem;
    font-weight: 400;
    font-family: var(--font-heading);
}

.main-hypertext a {
    position: relative;
    transition: text-decoration-color 0.25s ease-in,
        filter var(--main-transition-duration) ease-in;
}

.project a:hover h4::before,
.main-hypertext a:hover::before {
    content: '';
    display: block;
    height: 0.066rem;
    background: var(--header-bg-fill);
    background-attachment: fixed;
    background-position: -50%;
    position: absolute;
    bottom: -0.033rem;
    width: 100%;
}

.section h5 {
    font-family: var(--font-heading);
    font-size: 1.25rem;
    font-weight: 400;
    color: var(--main-text-color);
    transition: color var(--main-transition-duration) ease-in;
}

@media print {
    .section {
        margin-bottom: 1rem;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }

    .cut-out,
    .section h3,
    .section h4,
    .main-hypertext a,
    .night .cut-out,
    .night .section h3,
    .night .section h4,
    .night .main-hypertext a {
        background: unset;
        background-attachment: unset;
        -webkit-text-fill-color: unset;
        -webkit-background-clip: unset;
        background-clip: unset;
        background-position: unset;
        color: darkgray;
        filter: unset;
    }

    .main-hypertext a,
    .night .main-hypertext a {
        color: unset;
    }

    .section h5 {
        color: gray;
    }
}
