.skill {
    margin-bottom: .25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.skill h6 {
    flex: 1;
    font-family: var(--font-text);
    color: var(--main-text-color);
    transition: color var(--main-transition-duration) ease-in;
}

.skill span {
    display: block;
    width: 6rem;
    overflow: hidden;
    border-radius: .5rem;
    height: 1rem;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 0;
    transition: background-color var(--main-transition-duration) ease-in;
}

.skill a {
    display: inline-block;
    height: 100%;
    cursor: default;
    background: var(--header-bg-fill);
    background-attachment: fixed;
    background-position: var(--header-bg-position);
    transition: filter var(--main-transition-duration) ease-in;
}

.night .skill a {
    -webkit-filter: invert(var(--main-filter-amount));
    filter: invert(var(--main-filter-amount));
}

.night .skill span {
    background-color: rgba(255, 255, 255, 0.05);
}

@media print {
    .skill span {
        position: relative;
    }

    .skill a {
        position: absolute;
        inset: 0;
        margin: 0;
        padding: 0;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        background-attachment: unset;
        background-position: unset;
        background-size: cover;
        background-repeat: no-repeat;
        filter: unset;
    }

    #languages .skill span {
        font-size: 1rem !important;
        width: unset;
        border: unset;
        border-radius: unset;
        overflow: visible;
    }

    #languages .skill a {
        display: none;
    }
}
